import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-lemon-client-id': process.env.REACT_APP_CLIENT_ID,
    'x-lemon-client-secret': process.env.REACT_APP_CLIENT_SECRET,
    Authorization: process.env.REACT_APP_AUTH_ACCESS_TOKEN,
  },
});

export default http;
