import {Card, CardContent, Grid} from '@mui/material';
import React, {useContext} from 'react';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PageHeading from '../../../components/PageNavigation';
import walletApi from '../../../services/finance-api';
import {AppContext} from '../../../App';
import TransactionsList from '../components/TransactionsList';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const FinancePage = () => {
    const [loadingCount, setCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isRefreshing, setIsRefreshing] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [balance, setBalance] = React.useState<any>(null);
    const [transactions, setTransactions] = React.useState<any>(null);

    const {providerId} = useContext(AppContext);
    const {setError} = useContext(AppContext);
    const navigate = useNavigate()
    const fetchTransactions = async () => {
        try {
            setIsLoading(true);
            const transactions = await walletApi.getTransactions(page, providerId);
            setTransactions(transactions);
        } catch (err: any) {
            if (err.status === 401) {
                navigate('/')
            }
            setTransactions([]);
            toast.error(err.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } finally {
            setIsLoading(false);
            setCount(2);
        }
    };
    const fetchBalance = async () => {
        try {
            setIsLoading(true);
            const res = await walletApi.getBalance(providerId);
            setBalance(res.balance);
        } catch (err: any) {
            if (err.status === 401) {
                navigate('/')
            }
            setBalance(0);
            toast.error(err.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })

        } finally {
            isLoading && setIsLoading(false);
            setCount(2);
        }
    };

    React.useEffect(() => {
        fetchBalance();
    }, [isRefreshing]);
    React.useEffect(() => {
        fetchTransactions();
    }, [page, isRefreshing]);

    if ((isLoading && loadingCount === 0) || balance === null || transactions === null) {
        return <LoadingIndicator isLoading={true}/>;
    }
    // console.log(data);

    return (
        <Grid container lg={12} md={12} sm={12} xs={12} padding={3}>
            <Grid item lg={12} md={12} sm={12} xs={12} marginBottom={2}>
                <PageHeading
                    heading1='Events'
                    heading2='Finance'
                    isRefreshing={isRefreshing}
                    setIsRefreshing={setIsRefreshing}
                    backBtn
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container lg={12} md={12} xs={12} justifyContent='center' padding={3}>
                    {/* header  */}
                    <Grid item lg={4} md={4} sm={12} xs={12} marginBottom={3}>
                        <Card>
                            <CardContent className='bg-gray-200 text-center'>
                <span className=' font-sans text-lg font-semibold p-2 text-gray-500'>
                  TOTAL BALANCE :
                </span>
                                <span
                                    className={
                                        balance > 0
                                            ? 'font-black text-lg text-green-700 p-2 mr-4  rounded-xl'
                                            : 'font-black text-lg text-red-700 p-2 mr-4  rounded-xl'
                                    }>
                  {balance} SDG
                </span>

                                {/* btn's */}
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* body  */}
                    <Grid container lg={12} md={12} xs={10}>
                        <Grid item lg={12} md={12} xs={12}>
                            <span className='font-serif text-gray-600 text-2xl font-bold'>Transactions</span>
                        </Grid>
                        <Grid item lg={12} md={12} xs={9}>
                            <TransactionsList
                                transactions={transactions}
                                setPage={setPage}
                                isLoading={isLoading}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FinancePage;
