import { Grid } from '@mui/material';
import React from 'react';

const TabsLoadingIndicator = () => {
  return (
    <Grid container lg={12} md={12} justifyContent='center'>
      <div className='grid min-h-screen place-content-center'>
        <div className='flex items-center gap-2 text-xl text-gray-600'>
          <span className='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
          loading...
        </div>
      </div>
    </Grid>
  );
};

export default TabsLoadingIndicator;
