import { Route } from 'react-router-dom';
import ProtectedRoute from '../../components/ProtectedRoute';
import EventDetailsPage from './pages/event-details-page';
import EventsListPage from './pages/events-list-page';

const EventsRoutes = () => {
  return (
    <Route path={'/events'}>
      <Route
        path={'/events/'}
        element={
          <ProtectedRoute>
            <EventsListPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/events/:id'}
        element={
          <ProtectedRoute>
            <EventDetailsPage />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default EventsRoutes;
