import { Button, Grid, MenuItem, Paper, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TabsLoadingIndicator from '../../../../components/TabsLoadingIndicator';
import eventApi from '../../../../services/events-api';
import { AppContext } from '../../../../App';
import { toast } from 'react-toastify';

type props = {
  eventId: string;
  providerId: string;
};

type payload = {
  ticket_type_id: number;
  from?: Date;
  to?: Date;
};
const ScansStatistics = ({ eventId, providerId }: props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [ticketTypes, setTicketTypes] = useState<any>(null);
  const [payload, setPayload] = useState({
    from: null,
    to: null,
    mobile: '',
    status_id: undefined,
    type_id: '',
  });
  const [data, setData] = useState<any>(null);
  const { setError } = useContext(AppContext);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const types = await eventApi.getTicktTypes(eventId, providerId);
      setTicketTypes(types);
      const response = await eventApi.getScansStatistics(
        eventId,
        providerId,
        payload
      );
      setData(response);
    } catch (err: any) {
      if (err.status === 401) {
        navigate('/');
      }

      setData([]);

      toast.error(err.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  const handleDateRangeChange = (type: string, value: any) => {
    if (type === 'from') {
      setPayload({ ...payload, from: value });
    } else {
      setPayload({ ...payload, to: value });
    }
    // setIsRefreshing(isRefreshing+1); //
  };

  const handleTicketTypeChange = (value: any) => {
    setPayload({ ...payload, type_id: value });
    // setIsRefreshing(isRefreshing+1);
  };

  if (isLoading || !data) {
    return <TabsLoadingIndicator />;
  }

  return (
    <Grid container lg={12} md={12} spacing={2} justifyContent='space-around'>
      {/* filters  */}
      <Grid
        container
        lg={12}
        md={12}
        spacing={2}
        justifyContent='space-around'
        className='pb-5'
      >
        <Grid item lg={3} md={3} sm={6} className='flex justify-center px-4'>
          <TextField
            variant='outlined'
            id='from'
            label='from'
            type='datetime-local'
            fullWidth
            value={payload.from}
            onChange={(e) => handleDateRangeChange('from', e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} className='flex justify-center px-4'>
          <TextField
            variant='outlined'
            id='to'
            label='to'
            type='datetime-local'
            fullWidth
            value={payload.to}
            onChange={(e) => handleDateRangeChange('to', e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} className='flex justify-center '>
          <TextField
            variant='outlined'
            id='ticketType'
            label='ticket type'
            fullWidth
            select
            value={payload.type_id}
            onChange={(e) => handleTicketTypeChange(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value={''}>None</MenuItem>
            {ticketTypes.map((ticket: any) => (
              <MenuItem value={ticket.ticket_type_id}>
                {ticket.ticket_type_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={2} md={2} sm={12} className='flex justify-center '>
          <Button
            color='success'
            variant='outlined'
            fullWidth
            size='small'
            onClick={() => setIsRefreshing(isRefreshing + 1)}
          >
            apple
          </Button>
        </Grid>
      </Grid>
      {/* body  */}
      <Grid
        container
        lg={12}
        md={12}
        justifyContent='center'
        spacing={1}
        paddingTop={10}
      >
        {/* total  */}
        <Grid item lg={3} md={3} sm={6} xs={9}>
          <Paper elevation={6} className=' p-2  '>
            <span className=''>
              <h1 className='w-full pt-3 text-center uppercase text-base font-serif font-semibold text-gray-500 '>
                total tickets scan logs
              </h1>
            </span>
            <p className='w-full p-5 text-center font-medium text-4xl'>
              {data.total_tickets_scan_logs}
            </p>
          </Paper>
        </Grid>
        {/* success  */}
        <Grid item lg={3} md={3} sm={6} xs={9}>
          <Paper elevation={6} className=' p-2  '>
            <span className=''>
              <h1 className='w-full pt-3 text-center uppercase text-base font-serif font-semibold text-gray-500'>
                total success tickets scan
              </h1>
            </span>
            <p className='w-full p-5 text-center font-medium text-4xl'>
              {data.total_success_tickets_scan_logs}
            </p>
          </Paper>
        </Grid>
        {/* failed  */}
        <Grid item lg={3} md={3} sm={6} xs={9}>
          <Paper elevation={6} className=' p-2  '>
            <span className=''>
              <h1 className='w-full pt-3 text-center uppercase text-base font-serif font-semibold text-gray-500'>
                total failed tickets scan
              </h1>
            </span>
            <p className='w-full p-5 text-center font-medium text-4xl'>
              {data.total_failed_tickets_scan_logs}
            </p>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScansStatistics;
