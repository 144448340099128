import { Route } from 'react-router-dom';
import ProtectedRoute from '../../components/ProtectedRoute';
import ScannerPage from './pages/scanner-page';

const ScannerRoutes = () => {
  return (
    <Route path={'/scanner'}>
      <Route
        path={'/scanner/'}
        element={
          <ProtectedRoute>
            <ScannerPage />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};
export default ScannerRoutes;
