import { ArrowBack, NavigateNext, Refresh } from '@mui/icons-material';
import { Breadcrumbs, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type props = {
  heading1: string;
  previousUrl?: string;
  heading2?: string;
  heading3?: string;
  heading4?: string;
  heading5?: string;
  backBtn?: boolean;
  isRefreshing?: number;
  setIsRefreshing?: (value: any) => void;
};

const PageHeading = ({
  heading1,
  previousUrl,
  heading2,
  heading3,
  heading4,
  heading5,
  backBtn,
  isRefreshing = 1,
  setIsRefreshing,
}: props) => {
  const navigate = useNavigate();
  return (
    <Grid container lg={12} md={12} spacing={2} className='pl-4 '>
      <Grid item>
        {backBtn && (
          <ArrowBack
            color='success'
            fontSize='medium'
            className='  hover:text-green-500 hover:cursor-pointer hover:animate-pulse mr-2'
            onClick={() => navigate(-1)}
          />
        )}
        {setIsRefreshing && (
          <Refresh
            color='success'
            fontSize='medium'
            className='  hover:text-green-500 hover:cursor-pointer hover:animate-pulse '
            onClick={() => setIsRefreshing(isRefreshing + 1)}
          />
        )}
      </Grid>

      <Grid item>
        <Breadcrumbs separator={<NavigateNext fontSize='small' />} aria-label='breadcrumb'>
          <span className='text-xs'>{heading1}</span>
          {heading3 ? (
            <span
              className='text-xs hover:text-sm hover:text-green-500 hover:cursor-pointer hover:animate-pulse'
              onClick={() => navigate(`${previousUrl}`)}>
              {heading2}
            </span>
          ) : (
            <span className='text-xs font-bold text-green-900'>{heading2}</span>
          )}

          {heading3 && <span className='text-xs font-bold text-green-900'>{heading3}</span>}
          {heading4 && <span className='text-xs font-bold text-green-900 '>{heading3}</span>}
          {heading5 && <span className='text-xs font-bold text-green-900'>{heading3}</span>}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default PageHeading;
