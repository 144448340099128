import {
  AccountBalanceWallet,
  DocumentScanner,
  ListAlt,
  MiscellaneousServices,
} from '@mui/icons-material';
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../App';
import LoadingIndicator from '../../../components/LoadingIndicator';
import eventsApi from '../../../services/events-api';
import { toast } from 'react-toastify';

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();
  const { providerId, setProviderId, setError } = useContext(AppContext);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await eventsApi.getProviders();
      setData(res);
    } catch (err: any) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      }
      setData([]);
      toast.error(err.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const naviagte = useNavigate();

  if (isLoading || !data) {
    return <LoadingIndicator isLoading={true} />;
  }

  return (
    <Grid container lg={12} md={12} sm={12} xs={12} padding={3}>
      <List style={{ width: '100%', minHeight: 500 }}>
        <ListSubheader className='capitalize pb-3 '>
          <MiscellaneousServices color='success' />
          services
        </ListSubheader>
        <ListItem>
          <TextField
            label='Provider'
            size='small'
            select
            fullWidth
            value={providerId}
            onChange={(e) => setProviderId(e.target.value)}
          >
            {data.map((data: any) => (
              <MenuItem value={data.provider_id}>
                {data.event_provider.full_name}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>

        {providerId && (
          <>
            <ListItemButton onClick={() => naviagte('/events/')}>
              <ListItemAvatar>
                <ListAlt fontSize='large' color='success' />
              </ListItemAvatar>
              <ListItemText
                primary='Events'
                secondary='View all your events '
              />
            </ListItemButton>
            <ListItemButton onClick={() => naviagte('/wallet/')}>
              <ListItemAvatar>
                <AccountBalanceWallet fontSize='large' color='success' />
              </ListItemAvatar>
              <ListItemText
                primary='Wallet'
                secondary='View wallet balance and transactions'
              />
            </ListItemButton>
            <ListItemButton onClick={() => naviagte('/scanner/')}>
              <ListItemAvatar>
                <DocumentScanner fontSize='large' color='success' />
              </ListItemAvatar>
              <ListItemText primary='Scanner' secondary='Scan the ticket QR' />
            </ListItemButton>
          </>
        )}
      </List>
    </Grid>
  );
};

export default HomePage;
