import { CheckCircleOutline, DoDisturbOn } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import React from 'react';

const Invalid = () => {
  return (
    <div className='w-[100vw]'>
      <div
        style={{
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          paddingTop: '20px',
        }}
      >
        <div style={{ width: '100%', textAlign: 'center' }}>
          <p className='text-gray-700 font-serif font-extrabold text-2xl'>
            Invalid
          </p>
          <DoDisturbOn fontSize='large' color='error' />
        </div>
      </div>
      <div className='flex items-center justify-center mt-5 w-full'>
        <div className='w-[300px]'>
          <img
            width={'300px'}
            src={require('../../../../assets/images/nothing.jpg')}
            alt=''
          />
        </div>
      </div>
    </div>
  );
};

export default Invalid;
