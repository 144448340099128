import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import React from 'react';
import { validateMobileForQueryParams } from '../../../scripts/events-helpers';

type payload = {
  from: string | null;
  to: string | null;
  mobile: string;
  ticket_number: string;
  status_id: number | undefined;
  type_id?: number;
};

type props = {
  page: string;
  ticketTypes?: {}[] | any;
  payload: payload;
  setPayload: (value: any) => void;
  isRefreshing: number;
  setIsRefreshing: (value: number) => void;
  setCount: (value: number) => void;
  setPage: any;
};

const TicketsListFilters = ({
  ticketTypes,
  payload,
  setPayload,
  isRefreshing,
  setIsRefreshing,
  setCount,
  setPage,
  page,
}: any) => {
  // @ts-ignore
  return (
    <Grid container lg={12} md={12} sm={12} xs={12}>
      {/* <input type='text' className='border-gray-300 border-2 focus:border-blue-500' /> */}
      <Grid
        item
        lg={1}
        md={1}
        sm={1}
        xs={1}
        className='capitalize text-gray-600 font-serif font-semibold text-center'
      >
        filters
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <TextField
          label={page === 'scans' ? 'employee mobile' : 'user mobile'}
          size='small'
          value={payload.mobile}
          onChange={(e) => setPayload({ ...payload, mobile: e.target.value })}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>+249</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1}>
        <TextField
          label='ticket_number'
          size='small'
          value={payload.ticket_number}
          onChange={(e) =>
            setPayload({ ...payload, ticket_number: e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1}>
        {page === 'scans' ? (
          <TextField
            label='status'
            size='small'
            select
            fullWidth
            value={payload.status_id}
            onChange={(e) =>
              setPayload({ ...payload, status_id: e.target.value })
            }
          >
            <MenuItem value={'null'}>ALL</MenuItem>
            <MenuItem value={1}>Success</MenuItem>
            <MenuItem value={0}>Failed</MenuItem>
          </TextField>
        ) : (
          <TextField
            label='status'
            size='small'
            select
            fullWidth
            value={payload.status_id}
            onChange={(e) =>
              setPayload({ ...payload, status_id: e.target.value })
            }
          >
            <MenuItem value={'null'}>ALL</MenuItem>
            <MenuItem value={1}>CONFIRMED</MenuItem>
            <MenuItem value={2}>CONSUMED</MenuItem>
            {/* <MenuItem value={4}>DISABLED</MenuItem> */}
          </TextField>
        )}
      </Grid>
      {ticketTypes && (
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            label='type'
            size='small'
            select
            fullWidth
            value={payload.type_id}
            onChange={(e) =>
              setPayload({ ...payload, type_id: e.target.value })
            }
          >
            <MenuItem value={'null'}>ALL</MenuItem>
            {ticketTypes.map((ticket: any) => (
              <MenuItem value={ticket.ticket_type_id}>
                {ticket.ticket_type_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}

      <Grid item lg={2} md={2} sm={2} xs={2}>
        <TextField
          variant='outlined'
          value={payload.from}
          onChange={(e) => setPayload({ ...payload, from: e.target.value })}
          id='from'
          label='from'
          type='datetime-local'
          size='small'
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <TextField
          variant='outlined'
          value={payload.to}
          onChange={(e) => setPayload({ ...payload, to: e.target.value })}
          id='from'
          label='to'
          type='datetime-local'
          size='small'
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} paddingLeft={1}>
        <Button
          variant='outlined'
          fullWidth
          color='success'
          onClick={() => {
            setCount(0);
            setPage(0);
            setIsRefreshing(isRefreshing + 1);
          }}
        >
          apply
        </Button>
      </Grid>
    </Grid>
  );
};

export default TicketsListFilters;
