import React from 'react'
import { Route } from 'react-router-dom'
import LoginPage from './page/login-page'

const AuthRoutes = () => {
  return (
    <Route path={'/login'}>
      <Route path={'/login/'} element={<LoginPage/>}/>
    </Route>
  )
}

export default AuthRoutes