import http from './http';

const getEventDetails = async (event_id: string | any, providerId: string) => {
    try {
        const response = await http.get(
            `/provider_dashboard/events/${event_id}?provider_id=${providerId}`
        );
        return response.data;
    } catch (error: any) {
        throw error.response;
        console.log(error.response.status)
    }
};

export default getEventDetails;
