import {validateMobile} from './../../scripts/auth-helpers';
import http from './http';

const sendOtp = async (moblie: string) => {
    const mobile_number = validateMobile(moblie);
    try {
        await http.post('/auth/otp', {
            mobile_number,
        });
    } catch (err: any) {
        throw err.response;
    }
};

export default sendOtp;
