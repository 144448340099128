import React, { useCallback, useContext, useEffect, useState } from 'react';
import './scanner.css';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Progress from './Progress';
import TicketInfoDialog from './tick-info-dialog/TicketInfoDialog';
import validateTicket from '../../../services/scan-api/vaildate-ticket';
import { Toast } from 'react-toastify/dist/components';
import { toast } from 'react-toastify';
import { AppContext } from '../../../App';

// <-- this file is in /public.

type error = {
  code: number;
  message: string;
};
type ticket = {};

function Scanner() {
  const [isLoading, setIsLoading] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [ticketID, setTicketID] = useState(null);
  const [ticketData, setTicketData] = useState<any>(null);
  const { providerId } = useContext(AppContext);

  useEffect(() => {
    if (!ticketID) return;
    checkTicket(ticketID);
  }, [ticketID]);

  const checkTicket = useCallback(
    async (ticketId: string) => {
      try {
        setIsLoading(true);
        const res = await validateTicket(ticketId, providerId);
        setTicketData(res);
        setIsValid(true);
        setOpenInfoDialog(true);
      } catch (error: any) {
        toast.error(error.data.message);
        if (error.code === 401) {
        } else {
          setIsValid(false);
          setOpenInfoDialog(true);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [ticketData]
  );

  useEffect(() => {
    const html5QrcodeScanner = new Html5QrcodeScanner(
      'reader',
      { fps: 5, qrbox: { width: 300, height: 300 }, disableFlip: false },
      /* verbose= */ false
    );
    html5QrcodeScanner.render(
      (data: any) => {
        setTicketID(data);
      },
      (err: any) => console.info('error ->', err)
    );
    return () => {
      html5QrcodeScanner.clear();
    };
  }, []);

  return (
    <Grid
      container
      lg={12}
      md={12}
      sm={12}
      xs={12}
      flexDirection='column'
      padding={3}
      alignContent='center'
      alignItems='center'
    >
      <Progress isLoading={isLoading} />
      <TicketInfoDialog
        isOpen={openInfoDialog}
        onClose={() => {
          setOpenInfoDialog(false);
          setTicketID(null);
        }}
        isValid={isValid}
        {...(ticketData ? { ticketData: ticketData } : {})}
      />
      <Grid
        item
        lg={8}
        md={12}
        sm={12}
        xs={12}
        width={300}
        height={300}
        padding={10}
        alignContent='center'
        alignItems='center'
        marginTop={10}
        id='reader'
      />

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        padding={10}
        textOverflow='revert-layer'
      ></Grid>
    </Grid>
  );
}

export default Scanner;
