import http from './http';

const validateTicket = async (ticket_id: string, provider_id: string) => {
  try {
    const res = http.post(
      '/provider_dashboard/tickets',
      {
        ticket_id,
      },
      {
        params: {
          provider_id,
        },
      }
    );
    return (await res).data;
  } catch (error: any) {
    throw error.response;
  }
};

export default validateTicket;
