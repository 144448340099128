import { LocalPhone, Person, QrCode2, Visibility } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, IconButton } from '@mui/material';
import { green } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import Table from '../../../components/Table';
import { eventTicketsStatuses } from '../data';
import TicketsListFilters from './TicketsListFilters';
import { maskingUserMobile } from '../../../scripts/events-helpers';

type props = {
  isLoading: boolean;
  setPage: (value: number) => void;
  data: {} | any;
};
const TicketList = ({ data, isLoading, setPage }: props) => {
  const columns: GridColDef[] = [
    {
      field: 'ticketNumber',
      headerName: 'ticket number',
      // headerAlign: 'center',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 0.8,
    },
    {
      field: 'userName',
      headerName: 'user name',
      // headerAlign: 'center',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 0.8,
    },
    {
      field: 'userMobile',
      headerName: 'user mobile',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 0.8,
    },
    {
      field: 'bookingId',
      headerName: 'booking id',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 1,
    },
    {
      field: 'ticketType',
      headerName: 'ticket type',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 1,
    },

    {
      field: 'status',
      headerName: 'status',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '  text-gray-600',
      flex: 0.7,
    },
    {
      field: 'bookingTime',
      headerName: 'Booking Time',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '  text-gray-600',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'created at',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '  text-gray-600',
      flex: 1,
    },
    {
      field: 'viewQr',
      headerName: 'view qr ',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '  text-gray-600',
      flex: 0.5,
      renderCell: (params) => (
        <Grid container lg={12} md={12} sm={12} xs={12} paddingX={5}>
          <Button
            color='success'
            onClick={() => window.open(params.value, '_blank')?.focus()}
          >
            <QrCode2 />
          </Button>
        </Grid>
      ),
    },
  ];
  const rows = data?.map((data: any) => {
    return {
      id: data.ticket_id,
      ticketNumber: data.ticket_number,
      userName: data.user?.full_name || '.....',
      userMobile: maskingUserMobile(data.user?.mobile),
      bookingId: data.booking_id || '.....',
      ticketType: data.ticket_type.ticket_type_name,
      status: eventTicketsStatuses[data.status_id - 1],
      bookingTime: data.booking_time
        ? moment(data.booking_time).format('DD/MM/YYYY, h:mm:ss a')
        : null,
      createdAt: moment(data.created_at).format('DD/MM/YYYY, h:mm:ss a'),
      viewQr: data.qr_url,
    };
  });

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Table
        cols={columns}
        rowsData={rows}
        isLoading={isLoading}
        enablePagination
        pagination={setPage}
        rowHeight={35}
      />
    </Box>
  );
};

export default TicketList;
