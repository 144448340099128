import http from './http';

const getProviders = async () => {
    try {
        const res = await http.get(`/provider_dashboard/events_providers_employees`);
        return res.data;
    } catch (error: any) {
        throw error.response;
    }
};

export default getProviders;
