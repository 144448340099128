import http from './http';

const getBalance = async (providerId: string) => {
    try {
        const response = await http.get(
            `/provider_dashboard/provider_wallet?provider_id=${providerId}`
        );
        return response.data;
    } catch (err: any) {
        throw err.response;
    }
};

export default getBalance;
