import React from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import { Empty } from './Empty';
import { Button, CircularProgress, Grid, LinearProgress } from '@mui/material';
import LoadingIndicator from './LoadingIndicator';

function CustomPagination(isLoading: boolean) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Grid className=' flex justify-center relative space-x-1 py-4 w-full'>
      <Button
        disabled={page === 0 || isLoading ? true : false}
        variant='contained'
        color='success'
        onClick={() => apiRef.current.setPage(page - 1)}>
        Previous
      </Button>
      {/* <span className='p-2 rounded-full bg-gray-300 '>Page: {page + 1}</span> */}

      <Button
        disabled={isLoading}
        variant='contained'
        color='success'
        onClick={() => apiRef.current.setPage(page + 1)}>
        next
      </Button>
      {isLoading ? (
        <CircularProgress className='absolute right-8' />
      ) : (
        <span className='p-2 px-4 rounded-full bg-gray-300 absolute right-8  font-bold'>
          {page + 1}
        </span>
      )}
    </Grid>
  );
}

function CustomToolbar() {
  return <></>;
}

type tableProps = {
  isLoading: boolean;
  pagination: (value: number) => void;
  rowsData: any;
  cols: any;
  loaderTypePage?: string;
  enablePagination?: boolean;
  rowHeight?: number;
};

const Table = ({
  isLoading,
  pagination,
  rowsData,
  cols,
  loaderTypePage,
  enablePagination,
  rowHeight,
}: tableProps) => {
  return (
    <div className='flex h-auto'>
      <div className=' flex-grow'>
        <DataGrid
          className='border-2'
          disableColumnFilter
          autoHeight
          rowHeight={rowHeight || 100}
          loading={isLoading}
          rows={rowsData}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => pagination(newPage)}
          //   CellStyle='{StaticResource DataGridBorder}'
          components={{
            Toolbar: CustomToolbar,
            Pagination: () => (enablePagination ? CustomPagination(isLoading) : null),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: loaderTypePage ? LoadingIndicator : LinearProgress,
          }}
        />
      </div>
    </div>
  );
};

export default Table;
