import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import Table from '../../../components/Table';
import { eventTicketsStatuses, scanStatus } from '../data';
import {maskingUserMobile} from "../../../scripts/events-helpers";

type props = {
  isLoading: boolean;
  setPage: (value: number) => void;
  data: {} | any;
};
const ScansLog = ({ data, isLoading, setPage }: props) => {
  const columns: GridColDef[] = [
    {
      field: 'ticketId',
      headerName: 'ticket id',
      // headerAlign: 'center',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 1,
    },
    {
      field: 'ticketNumber',
      headerName: 'ticket number',
      // headerAlign: 'center',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 1,
    },
    {
      field: 'userName',
      headerName: 'user name',
      // headerAlign: 'center',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 1,
    },
    {
      field: 'userMobile',
      headerName: 'user mobile',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'status',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '  text-gray-600',
      flex: 0.5,
    },
    {
      field: 'note',
      headerName: 'note',
      // headerAlign: 'center',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',

      flex: 1,
    },
    {
      field: 'employeeName',
      headerName: 'employee name',
      // headerAlign: 'center',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 1,
    },
    {
      field: 'employeeMobile',
      headerName: 'employee mobile',
      // headerAlign: 'center',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '   text-gray-600',
      flex: 1,
    },

    {
      field: 'createdAt',
      headerName: 'created at',
      // headerAlign: 'center',
      headerClassName: ' uppercase bg-green-700 text-white font-serif',
      cellClassName: '  text-gray-600',
      flex: 1,
    },
  ];
  const rows = data.map((data: any) => {
    return {
      id: data.log_id,
      ticketId: data.ticket_id,
      ticketNumber: data.ticket.ticket_number,
      userName: data.ticket.user?.full_name || '.....',
      userMobile: maskingUserMobile(data.ticket.user?.mobile) || '.....',
      status: scanStatus[data.status_id],
      note: data.note || '.....',
      employeeName: data.employee?.full_name || '.....',
      employeeMobile: data.employee?.mobile || '.....',
      createdAt: moment(data.created_at).format('DD/MM/YYYY, h:mm:ss a'),
    };
  });

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Table
        cols={columns}
        rowsData={rows}
        isLoading={isLoading}
        enablePagination
        pagination={setPage}
        rowHeight={35}
      />
    </Box>
  );
};

export default ScansLog;
