import React, { useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppContext } from './App';
import ProtectedRoute from './components/ProtectedRoute';
import AppRoutes from './modules/app/Routes';
import AuthRoutes from './modules/auth/Routes';
import EventsRoutes from './modules/events/Routes';
import FinanceRoutes from './modules/finance/Route';
import ScannerRoutes from './modules/scanner/Routes';

const MainRoutes = () => {
  const { providerId } = useContext(AppContext);
  const navigte = useNavigate();

  useEffect(() => {
    if (!providerId) {
      navigte('/');
    }
  }, []);
  return (
    <Routes>
      {AppRoutes()}
      {AuthRoutes()}
      {ScannerRoutes()}
      {EventsRoutes()}
      {FinanceRoutes()}
    </Routes>
  );
};

export default MainRoutes;
