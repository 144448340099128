import moment from 'moment';
import {validateMobileForQueryParams} from '../../scripts/events-helpers';
import http from './http';

type payload = {
    from: string | null;
    to: string | null;
    mobile: string;
    ticket_number:string
    status_id: any;
    type_id: number | undefined | string;
};

const getScansLogs = async (
    eventId: string,
    providerId: string,
    page: number,
    payload: payload
) => {
    try {
        const response = await http.get(
            `/provider_dashboard/tickets_scan_logs?provider_id=${providerId}&event_id=${eventId}&page_size=25&page_number=${page}${
                payload.mobile && payload.mobile !== ''
                    ? `&mobile=${validateMobileForQueryParams(payload.mobile)}`
                    : ''
            }${
                payload.ticket_number && payload.ticket_number !== ''
                    ? `&ticket_number=${payload.ticket_number}`
                    :''
            }${
                payload.status_id !== undefined && payload.status_id !== "null" ? `&status_id=${payload.status_id}` : ''
            }${payload.from ? `&from_date=${moment(payload.from).utc()}` : ''}${
                payload.to ? `&to_date=${moment(payload.to).utc()}` : ''
            }\n      `
        );
        return response.data;
    } catch (err: any) {
        throw err.response;
    }
};

export default getScansLogs;
