import http from './http';

const getTransactions = async (page: number, providerId: string) => {
    try {
        const response = await http.get(
            `/provider_dashboard/provider_wallet/transactions?page_size=50&page_number=${page}&provider_id=${providerId}`
        );
        return response.data;
    } catch (err: any) {
        throw err.response;
    }
};

export default getTransactions;
