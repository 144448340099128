import {Button, CircularProgress, Grid, TextField} from '@mui/material';
import {useCallback, useContext} from 'react';
import authApi from '../../../services/auth-api';
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../../App";
import {toast} from "react-toastify";

type mobileFormProps = {
    isLoading: boolean;
    setIsLoading: (value: boolean) => void;
    setIsverified: (value: boolean) => void;
    setMobile: (value: string) => void;
    mobile: string;
};

const MobileForm = (props: mobileFormProps) => {

    const navigate = useNavigate()
    const {setError} = useContext(AppContext);

    const sendOTP = useCallback(async () => {
        try {
            props.setIsLoading(true);
            await authApi.sendOtp(props.mobile);
            props.setIsverified(true);
        } catch (err: any) {

            toast.error(err.data.message,{
                position: toast.POSITION.BOTTOM_RIGHT
            })

        } finally {
            props.setIsLoading(false);
        }
    }, [props.mobile]);

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        sendOTP();
    };

    return (
        <form className='login-form' onSubmit={handleSubmit}>
            <Grid container lg={12} md={12} sm={12} xs={12} spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    {' '}
                    <TextField
                        // className='mobileInput'
                        fullWidth
                        autoFocus
                        type='tel'
                        id='exampleFormControlInput2'
                        placeholder='09XX XXX XXX'
                        onChange={(e) => props.setMobile(e.target.value)}
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button
                        fullWidth
                        variant='contained'
                        color='success'
                        type='submit'
                        disabled={props.isLoading}>
                        {props.isLoading ? <CircularProgress color='success' size={30}/> : 'Submit'}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default MobileForm;
