export const validateMobile = (mobileNumber: string) => {
  let mobile = mobileNumber;
  if (mobile[0] === '0') {
    mobile = mobile.substring(1);
  }
  return `+249${mobile}`;
};

export const handleTokenStore = (token: string) => {
  localStorage.setItem('token', JSON.stringify(token));
};
