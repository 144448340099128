import http from './http';

const getEvents = async (page: number, providerId: string, page_size?: number) => {
    try {
        const res = await http.get(
            `/provider_dashboard/events?page_number=${page}&page_size=10&provider_id=${providerId}`
        );
        return res.data;
    } catch (error: any) {
        throw error.response;
    }
};

export default getEvents;
