import getEventDetails from './get-event-details';
import getEventTickets from './get-event-tickets';
import getEvents from './get-events';
import getScansLogs from './get-scan-logs';
import getSalesStatistics from './get-sales-statistics';
import getTicktTypes from './get-ticket-types';
import getUsageStatistics from './get-usage-statistics';
import getScansStatistics from './get-scans-statistics';
import getProviders from './get-providers';

export default {
  getProviders,
  getEvents,
  getEventDetails,
  getEventTickets,
  getSalesStatistics,
  getUsageStatistics,
  getTicktTypes,
  getScansLogs,
  getScansStatistics,
};
