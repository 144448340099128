import { Button, CircularProgress } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authApi from '../../../services/auth-api';
import { AppContext } from '../../../App';
import { toast } from 'react-toastify';

type otpFormProps = {
  isLoading: boolean;
  mobile: string;
  setIsLoading: (value: boolean) => void;
};

const OtpForm = (props: otpFormProps) => {
  const [code, setCode] = useState('');
  const [ssnValues, setValue] = useState({
    ssn1: '',
    ssn2: '',
    ssn3: '',
    ssn4: '',
  });
  const navigate = useNavigate();
  const { setError } = useContext(AppContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split('-');
    // num = num.concat(value)
    // setNum(`${num}${value}`)
    setCode(`${code}${value}`);
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 4) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
        ) as HTMLElement | null;

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }

    setValue({
      ...ssnValues,
      [`ssn${fieldIndex}`]: value,
    });
  };

  const handleLogin = async () => {
    try {
      props.setIsLoading(true);
      authApi.login(props.mobile, code, navigate);
    } catch (err: any) {
      toast.error(err.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      props.setIsLoading(false);
      navigate('/login');
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleLogin();
    // handleLogin();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        id='otp'
        className='flex flex-row justify-center text-center px-2 mt-5'
      >
        <input
          autoFocus
          className='m-2 border-2 border-black h-10 w-10 text-center form-control rounded'
          type='text'
          id='first'
          maxLength={1}
          onChange={handleChange}
          name='ssn-1'
        />
        <input
          className='m-2 border-2 border-black h-10 w-10 text-center form-control rounded'
          type='text'
          id='second'
          maxLength={1}
          onChange={handleChange}
          name='ssn-2'
        />
        <input
          className='m-2 border-2 border-black h-10 w-10 text-center form-control rounded'
          type='text'
          id='third'
          maxLength={1}
          onChange={handleChange}
          name='ssn-3'
        />
        <input
          className='m-2 border-2 border-black h-10 w-10 text-center form-control rounded'
          type='text'
          id='fourth'
          maxLength={1}
          onChange={handleChange}
          name='ssn-4'
        />
      </div>

      <div className='w-full flex justify-center pt-4'>
        <Button
          fullWidth
          variant='contained'
          color='success'
          type='submit'
          disabled={props.isLoading}
        >
          {props.isLoading ? (
            <CircularProgress color='success' size={30} />
          ) : (
            'verify'
          )}
        </Button>
      </div>
    </form>
  );
};

export default OtpForm;
