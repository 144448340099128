import axios, { AxiosHeaders, AxiosInterceptorManager, AxiosInterceptorOptions } from 'axios';

const auth = 'Bearer ';

const http = axios.create({
  baseURL: process.env.REACT_APP_EVENTS_URL,
  headers: {
    'Content-Type': 'application/json',
    lang: 'en',
    'x-lemon-client-id': process.env.REACT_APP_CLIENT_ID,
    'x-lemon-client-secret': process.env.REACT_APP_CLIENT_SECRET,
  },
});

http.interceptors.request.use((config: any) => {
  let token = JSON.parse(localStorage.getItem('token') || '');
  config.headers['Authorization'] = auth.concat(token);

  return config;
});

export default http;
