import {
  AirplaneTicket,
  Build,
  CalendarToday,
  Close,
  ColorLens,
  ConfirmationNumber,
  CreditCard,
  Description,
  Fingerprint,
  FmdGood,
  Group,
  Image,
  LocalTaxi,
  LocationOn,
  Note,
  Percent,
  Person,
  Phone,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Rating,
  Toolbar,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
// import { eventsStatuses } from '../../data';
type props = {
  data: [] | any;
};

const eventsStatuses: {
  name: string;
  color:
    | 'primary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'default'
    | 'secondary';
}[] = [
  { name: 'New', color: 'primary' },
  { name: 'Canceled', color: 'error' },
  { name: 'Hidden', color: 'info' },
  { name: 'Start Booking', color: 'success' },
  { name: 'End Booking', color: 'warning' },
];

const MainDetails = ({ data }: props) => {
  const [openImagesDialog, setOpenImagesDialog] = useState(false);

  const t = (str: string) => str;

  return (
    <Grid>
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className=' border-2 p-1  bg-gray-100 w-full'
      >
        {/* <DriverTreeView /> */}
        <Grid item lg={12} className='pb-10 pt-5'>
          {/* profile img  */}
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className='flex justify-center  cursor-pointer'
          >
            <Avatar
              src={data.event_provider?.provider_logo}
              sx={{ width: 100, height: 100 }}
            />
          </Grid>

          {/* header */}
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            marginBottom={3}
            textAlign='center'
          >
            <p className='font-serif font-extrabold text-gray-700 text-2xl'>
              {data.event_name}
            </p>
            <div className='mt-5 gap-2 flex items-center justify-center'>
              <Chip
                sx={{
                  fontSize: '20px',
                  p: 1,
                  pb: 2,
                }}
                color={eventsStatuses[data.event_status_id - 1].color}
                variant='outlined'
                label={t(eventsStatuses[data.event_status_id - 1].name)}
              />
            </div>
          </Grid>
          <Divider variant='middle' />
          {/* body */}
          <Grid
            container
            lg={12}
            md={12}
            paddingX={4}
            flexDirection='row'
            spacing={2}
          >
            {/* leftside */}
            <Grid item lg={4} md={4}>
              <List sx={{ widht: '100%' }}>
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Person className='text-black w-2' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Event provider')}
                    secondary={data.event_provider?.full_name || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Description className='text-black w-2' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('About event')}
                    secondary={data.event_desc || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* note */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Note className='text-black w-2' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Event note')}
                    secondary={data.event_note || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 2 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FmdGood className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <div className='flex truncate justify-between items-center w-full'>
                    <div className='truncate w-full'>
                      <ListItemText
                        primary={t('Event address')}
                        secondary={data.event_address || '...'}
                      />
                    </div>
                  </div>
                </ListItem>
                <Divider variant='inset' component='li' />
              </List>
            </Grid>
            {/* rigthside */}
            <Grid item lg={4} md={4}>
              <List>
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ConfirmationNumber className='text-black w-2' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('All tickets qty')}
                    secondary={data.all_tickets_qty}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 2 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ConfirmationNumber className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Sold tickets qty')}
                    secondary={
                      data.all_tickets_qty
                        ? data.all_tickets_qty - data.remaining_tickets_qty
                        : '...'
                    }
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 3 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ConfirmationNumber className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Remaining tickets qty')}
                    secondary={data.remaining_tickets_qty}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/*  */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ConfirmationNumber className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Max tickets qty per user')}
                    secondary={data.max_tickets_qty_per_user}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/*  */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ConfirmationNumber className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Min tickets qty per booking')}
                    secondary={data.min_tickets_qty_per_booking}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* booking */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <AirplaneTicket className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Require booking time')}
                    secondary={data.require_booking_time ? t('yes') : t('no')}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* images */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Image className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <div className='flex truncate justify-between items-center w-full'>
                    <div className='truncate w-full'>
                      <ListItemText
                        primary={t('Event images')}
                        secondary={
                          (data.event_images?.length || 0) + ' ' + t('images')
                        }
                      />
                    </div>
                    <div className='p-1'>
                      <Button
                        color='info'
                        variant='outlined'
                        endIcon={<Image />}
                        onClick={() => setOpenImagesDialog(true)}
                      >
                        {t('Show images')}
                      </Button>
                    </div>
                  </div>
                </ListItem>
                <Divider variant='inset' component='li' />
              </List>
            </Grid>
            {/* side3 */}
            <Grid item lg={4} md={4}>
              <List sx={{ widht: '100%' }}>
                {/* item 3 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Percent className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Lemon percentage')}
                    secondary={data.lemon_percentage || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CalendarToday className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Event start date')}
                    secondary={
                      moment(data.event_start_date).format(
                        'DD/MM/YYYY, h:mm:ss a'
                      ) || '...'
                    }
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 4 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CalendarToday className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Event end date')}
                    secondary={
                      moment(data.event_end_date).format(
                        'DD/MM/YYYY, h:mm:ss a'
                      ) || '...'
                    }
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CalendarToday className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Start booking at')}
                    secondary={
                      moment(data.start_booking_at).format(
                        'DD/MM/YYYY, h:mm:ss a'
                      ) || '...'
                    } //FIXME:
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item  */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CalendarToday className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('End booking at')}
                    secondary={
                      moment(data.end_booking_at).format(
                        'DD/MM/YYYY, h:mm:ss a'
                      ) || '...'
                    }
                  />
                </ListItem>
                {/* item 4 */}
                <Divider variant='inset' component='li' />
              </List>
            </Grid>
          </Grid>
          {/* <Divider variant='middle' /> */}
        </Grid>
      </Grid>
      <Dialog
        open={openImagesDialog}
        onClose={() => setOpenImagesDialog(false)}
        fullScreen
      >
        <Box className=' bg-green-700 text-white flex justify-end'>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                setOpenImagesDialog(false);
              }}
              aria-label='close'
            >
              <Close />
            </IconButton>
          </Toolbar>
        </Box>
        <ImageList
          sx={{ width: '100%', padding: 10 }}
          variant='woven'
          cols={3}
          gap={8}
          rowHeight={250}
        >
          {data.event_images?.map((item: any) => (
            <ImageListItem key={item.url}>
              <img
                src={`${item.url}?w=250&h=250&fit=crop&auto=format`}
                srcSet={`${item.url}?w=250&h=250&fit=crop&auto=format&dpr=2 2x`}
                alt={item.id}
                loading='lazy'
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Dialog>
    </Grid>
  );
};

export default MainDetails;
