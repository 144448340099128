import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

type props = {
  isLoading: boolean;
};

const LoadingIndicator = ({ isLoading }: props) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
      onClick={() => {}}>
      <span className='font-mono text-lg font-semibold pr-3 uppercase'>Loading</span>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default LoadingIndicator;
