import { makeStyles, Tab, Tabs, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { Box } from '@mui/system';
import React, { useMemo } from 'react';
import MainDetails from './tabs/MainDetails';
import Scans from './tabs/Scans';
import SalesStatistic from './tabs/SalesStatistic';
import Tickets from './tabs/Tickets';
import UsageStatistic from './tabs/UsageStatistic';
import ScansStatistics from './tabs/ScansStatistics';
import DashboardPage from '../../dashboard/pages/dashboard-page';
import { createSearchParams, useSearchParams } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className='min-h-full'
      {...other}
    >
      {value === index && <Box sx={{ p: 4, height: 750 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type props = {
  data: {} | any;
};

const EventDetails = ({ data }: props) => {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchParams({ tab: newValue });
  };

  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({ tab: '0' })
  );

  const currentTab = useMemo(
    () => Number(searchParams.get('tab')),
    [searchParams]
  );

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ border: 0.1, borderColor: 'divider', paddingX: 2 }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          scrollButtons='auto'
          centered
          aria-label='basic tabs example'
        >
          <Tab label='Details' {...a11yProps(0)} />
          <Tab label='Tickets' {...a11yProps(1)} />
          <Tab label='Tickets Sales Statistic' {...a11yProps(2)} />
          <Tab label='Tickets Usage Statistic' {...a11yProps(3)} />
          <Tab label='Scans Log' {...a11yProps(4)} />
          <Tab label='Scans Statistic' {...a11yProps(5)} />
          <Tab label='Dashboard' {...a11yProps(6)} />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <MainDetails data={data} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Tickets eventId={data.event_id} providerId={data.provider_id} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <SalesStatistic eventId={data.event_id} providerId={data.provider_id} />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        <UsageStatistic eventId={data.event_id} providerId={data.provider_id} />
      </TabPanel>
      <TabPanel value={currentTab} index={4}>
        <Scans eventId={data.event_id} providerId={data.provider_id} />
      </TabPanel>
      <TabPanel value={currentTab} index={5}>
        <ScansStatistics
          eventId={data.event_id}
          providerId={data.provider_id}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={6}>
        <DashboardPage />
      </TabPanel>
    </Box>
  );
};

export default EventDetails;
