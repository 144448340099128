export const validateMobileForQueryParams = (mobileNumber: string) => {
  let mobile = mobileNumber;
  if (mobile[0] === '0') {
    mobile = mobile.substring(1);
  }
  return `%2B249${mobile}`;
};

export  const maskingUserMobile =(mobile ?:string)=>{
  if(mobile){
    let masked_value= mobile.slice(0,-4)
    masked_value = masked_value.concat("****")
    return masked_value
    // return mobile

  }else {
    return '.....'
  }
}
