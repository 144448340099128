import { Grid } from '@mui/material';
import React, { useState } from 'react';
import NavBar from './components/theme/NavBar';
import MainRoutes from './Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AppContext = React.createContext<any>(null);

function App() {
  const [providerId, setProviderId] = useState(null);
  const [error, setError] = useState({
    isOpen: true,
    errorMsg: null,
  });

  return (
    <AppContext.Provider value={{ providerId, setProviderId, setError }}>
      <Grid container lg={12} md={12} xs={12}>
        <NavBar />
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <MainRoutes />
        </Grid>

        <ToastContainer autoClose={4000} />
      </Grid>
    </AppContext.Provider>
  );
}

export default App;
