import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../App';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PageHeading from '../../../components/PageNavigation';
import eventsApi from '../../../services/events-api';
import EventDetails from '../components/EventDetails';
import { toast } from 'react-toastify';

const EventDetailsPage = () => {
  const [loadingCount, setCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(0);
  const [data, setData] = React.useState<any>(null);
  const { id } = useParams();
  const { providerId, setError } = useContext(AppContext);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const event_details = await eventsApi.getEventDetails(id, providerId);
      setData(event_details);
    } catch (err: any) {
      if (err.status === 401) {
        navigate('/');
      }
      setData([]);
      toast.error(err.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setIsLoading(false);
      setCount(2);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  if (isLoading || !data) {
    return <LoadingIndicator isLoading={true} />;
  }

  return (
    <Grid container padding={3}>
      <Grid item lg={12} md={12} sm={12} xs={12} marginBottom={2}>
        <PageHeading
          heading1='Events'
          heading2='Event List'
          heading3='Event Details'
          previousUrl='/events/'
          backBtn
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <EventDetails data={data} />
      </Grid>
    </Grid>
  );
};

export default EventDetailsPage;
