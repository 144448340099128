import { useContext } from 'react';
import { AppContext } from '../../../App';
import { useParams } from 'react-router-dom';

const DashboardPage = () => {
  const { providerId } = useContext(AppContext);

  const { id } = useParams();

  function Iframe(props: any) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
      />
    );
  }

  const iframe =
    '<iframe\n' +
    `    src=${
      process.env.REACT_APP_DASHBOARD_URL ||
      'https://bi.golemon.app/public/dashboard/56bf04db-a247-4f9f-85eb-e01c9c9b36a9?event_id='
    }${id}\n` +
    '    frameborder="0"\n' +
    '    width="100%"\n' +
    '    height="850"\n' +
    '    allowtransparency\n' +
    '></iframe>';

  return (
    <div className='App'>
      <Iframe iframe={iframe} />,
    </div>
  );
};

export default DashboardPage;
