import Scanner from '../components/Scanner';
// import QrScan from 'react-qr-reader'

const ScannerPage = () => {
  return (
    <>
      <Scanner />
    </>
  );
};

export default ScannerPage;
