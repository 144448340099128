import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginForm from '../components/LoginForm';
import './login-page.css';
import { Grid } from '@mui/material';
import { green } from '@mui/material/colors';

function Copyright(props: any) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit' href='http://lemontaxi.sd/'>
        Lemon Labs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='xs' className=' md:pt-36'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={require('../../../assets/images/favicon.ico')}
            alt='LEMON LABS'
            className='w-36'
          />
          <Grid
            container
            lg={7}
            sm={7}
            md={7}
            xs={8}
            className='p-1 pl-10 font-serif text-md font-semibold'
          >
            <h3>Lemon Event Portal</h3>
          </Grid>
          <Avatar sx={{ m: 1, bgcolor: green[600] }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <Box sx={{ mt: 1, ml: 2 }}>
            <LoginForm />
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
