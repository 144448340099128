import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import TabsLoadingIndicator from '../../../../components/TabsLoadingIndicator';
import eventApi from '../../../../services/events-api';
import TicketList from '../TicketList';
import TicketsListFilters from '../TicketsListFilters';
import { AppContext } from '../../../../App';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type props = {
  eventId: string;
  providerId: string;
};

const Tickets = ({ eventId, providerId }: props) => {
  const [loadingCount, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [page, setPage] = useState(0);
  const [data, setData] = useState<any>(null);
  const [ticketTypes, setTicketTypes] = useState<any>(null);
  const [payload, setPayload] = useState({
    from: null,
    to: null,
    mobile: '',
    ticket_number: '',
    status_id: undefined,
    type_id: undefined,
  });
  const { setError } = useContext(AppContext);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const types = await eventApi.getTicktTypes(eventId, providerId);
      setTicketTypes(types);
      const tickets = await eventApi.getEventTickets(
        eventId,
        providerId,
        page,
        payload
      );
      setData(tickets);
    } catch (err: any) {
      if (err.status === 401) {
        navigate('/');
      }

      setData([]);
      setError({
        isOpen: true,
        errorMsg: err.data.message,
      });
      toast.error(err.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setIsLoading(false);
      setCount(2);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, isRefreshing]);

  if ((isLoading && loadingCount === 0) || !data) {
    return <TabsLoadingIndicator />;
  }

  return (
    <Grid container lg={12} md={12} sm={12} xs={12}>
      <TicketsListFilters
        page='ticket'
        ticketTypes={ticketTypes}
        setPayload={setPayload}
        payload={payload}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
        setPage={setPage}
        setCount={setCount}
      />
      <TicketList data={data} isLoading={isLoading} setPage={setPage} />
    </Grid>
  );
};

export default Tickets;
