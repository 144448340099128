import moment from 'moment';
import { validateMobileForQueryParams } from '../../scripts/events-helpers';
import http from './http';

type payload = {
  from: string | null;
  to: string | null;
  mobile: string;
  status_id: number | undefined;
  type_id: any;
};

const getSalesStatistics = async (
  eventId: string,
  providerId: string,
  payload: payload
) => {
  try {
    const response = await http.get(
      `/provider_dashboard/tickets/statistic/purchased?event_id=${eventId}&provider_id=${providerId}${
        payload.mobile && payload.mobile !== ''
          ? `&mobile=${validateMobileForQueryParams(payload.mobile)}`
          : ''
      }${payload.type_id ? `&ticket_type_id=${payload.type_id}` : ''}${
        payload.from ? `&from_date=${moment(payload.from).utc()}` : ''
      }${payload.to ? `&to_date=${moment(payload.to).utc()}` : ''}`
    );
    return response.data;
  } catch (err: any) {
    throw err.response;
  }
};

export default getSalesStatistics;
