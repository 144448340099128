import { handleTokenStore } from './../../scripts/auth-helpers';
import { validateMobile } from '../../scripts/auth-helpers';
import http from './http';
import { toast } from 'react-toastify';

const login = (
  moblie: string,
  code: string,
  navigte: (value: string) => void
) => {
  const mobile_number = validateMobile(moblie);
  let res;
  http
    .post('/auth/mobile_login', {
      code,
      mobile_number,
    })
    .then(async (response) => {
      await handleTokenStore(response.data.access_token);
      navigte('/');
      toast.success('Login Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    })
    .catch((err) => err.response)
    .finally(() => {});
  return res;
};

export default login;
