import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import MobileForm from './MobileForm';
import OtpForm from './OtpForm';
// import './LoginForm.css';

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsverified] = useState(false);
  const [mobile, setMobile] = useState('');

  return (
    <div>
      {isVerified ? (
        <OtpForm isLoading={isLoading} setIsLoading={setIsLoading} mobile={mobile} />
      ) : (
        <MobileForm
          setMobile={setMobile}
          mobile={mobile}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsverified={setIsverified}
        />
      )}
    </div>
  );
};

export default LoginForm;
