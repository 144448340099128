import React from 'react';
import { Route } from 'react-router-dom';
import FinancePage from './pages/finance-page';

const FinanceRoutes = () => {
  return (
    <Route path='/wallet'>
      <Route path='/wallet/' element={<FinancePage />} />
    </Route>
  );
};

export default FinanceRoutes;
