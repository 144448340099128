import {Box} from '@mui/material';
import {GridColDef} from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import Table from '../../../components/Table';

type props = {
    transactions: any;
    isLoading: boolean;
    setPage: (value: number) => void;
};

const TransactionsList = ({transactions, isLoading, setPage}: props) => {
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: ' font-bold text-gray-600',
            flex: 1,
            disableColumnMenu: true,
        },
        {
            field: 'reference',
            headerName: 'REFERENCE_TYPE',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: '',
            flex: 3,
            disableColumnMenu: true,
        },
        {
            field: 'amount',
            headerName: 'AMOUNT',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: (params: any) =>
                params.value > 0
                    ? 'text-bold text-green-700 text-center '
                    : 'text-bold text-red-700 text-center ',
            disableColumnMenu: true,
        },
        {
            field: 'narration',
            headerName: 'NARRATION',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: '',
            disableColumnMenu: true,
            flex: 3,
        },
        {
            field: 'created',
            headerName: 'CREATED_AT',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: '',
            type: 'date',
            flex: 2,
            disableColumnMenu: true,
        },
    ];

    const rows = transactions.map((transaction: any) => {
        return {
            id: transaction.id,
            reference: transaction.reference_type.reference_type,
            amount: transaction.amount,
            narration: transaction.narration,
            created: moment(transaction.created_at).format('DD/MM/YYYY, h:mm:ss a'),
            action: transaction,
        };
    });

    return (
        <Box sx={{height: '100%', width: '100%'}}>
            <Table
                cols={columns}
                rowsData={rows}
                rowHeight={30}
                isLoading={isLoading}
                enablePagination
                pagination={setPage}
            />
        </Box>
    );
};

export default TransactionsList;
