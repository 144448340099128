import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ThemeProvider } from '@mui/system';
import { createTheme, Grid } from '@mui/material';
import { CheckCircleOutline, DoDisturbOn } from '@mui/icons-material';
import Invalid from './Invalid';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    secondary: {
      // This is green.A700 as hex.
      main: '#2D8A3A',
    },
  },
});

type dialogProps = {
  isOpen: boolean;
  onClose: any;
  isValid: boolean;
  ticketData?: any;
};

const TicketInfoDialog = ({
  isOpen,
  onClose,
  isValid,
  ticketData,
}: dialogProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative', display: 'flex', justifyContent: 'end' }}
          color='secondary'
        >
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={onClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {isValid ? (
          <>
            <div
              style={{
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
                paddingTop: '20px',
              }}
            >
              <div style={{ width: '100%', textAlign: 'center' }}>
                <p className='text-gray-700 font-serif font-extrabold text-2xl'>
                  Valid
                </p>
                <CheckCircleOutline fontSize='large' color='success' />
              </div>

              <List sx={{ width: '400px' }}>
                <ListItem>
                  <ListItemText
                    primary='Ticket ID'
                    secondary={ticketData.ticket_id}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary='Ticket Type'
                    secondary={ticketData.ticket_type?.ticket_type_name}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary='Booking ID'
                    secondary={ticketData.booking_id}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary='Event ID'
                    secondary={ticketData.ticket_type?.event_id}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary='User name'
                    secondary={ticketData.user?.full_name}
                  />
                </ListItem>
              </List>
            </div>
          </>
        ) : (
          <Invalid />
        )}
        <div className='flex mt-5 items-center w-full justify-center'>
          <Button variant='contained' onClick={onClose} color='inherit'>
            Dismiss
          </Button>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default TicketInfoDialog;
