import http from './http';

const getTicktTypes = async (event_id: string, provider_id: string) => {
    try {
        const response = await http.get(
            `/provider_dashboard/event_ticket_types?provider_id=${provider_id}&event_id=${event_id}&page_number=0&page_size=1000`
        );
        return response.data;
    } catch (err: any) {
        throw err.response;
    }
};

export default getTicktTypes;
