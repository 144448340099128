import {Grid} from '@mui/material';
import React, {useContext} from 'react';
import {AppContext} from '../../../App';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PageHeading from '../../../components/PageNavigation';
import eventsApi from '../../../services/events-api';
import EventsList from '../components/EventsList';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const EventsListPage = () => {
    const [loadingCount, setCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isRefreshing, setIsRefreshing] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState<any>([]);
    const navigate = useNavigate()
    const {providerId, setError} = useContext(AppContext);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await eventsApi.getEvents(page, providerId, 100);
            setData(res);
        } catch (err: any) {
            if (err.status === 401) {
                navigate('/')
            }
            setData([])
            setError({
                isOpen: true,
                errorMsg: err.data.message
            })
            toast.error(err.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } finally {
            setIsLoading(false);
            setCount(2);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [page, isRefreshing]);

    if (isLoading && loadingCount === 0) {
        return <LoadingIndicator isLoading={true}/>;
    }
    return (
        <Grid container padding={3}>
            <Grid item lg={12} md={12} sm={12} xs={12} marginBottom={2}>
                <PageHeading
                    heading1='Events'
                    heading2='Event List'
                    backBtn
                    isRefreshing={isRefreshing}
                    setIsRefreshing={setIsRefreshing}
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <EventsList isLoading={isLoading} setPage={setPage} data={data}/>
            </Grid>
        </Grid>
    );
};

export default EventsListPage;
