import * as React from 'react';
import Box from '@mui/material/Box';
import {GridColDef} from '@mui/x-data-grid';
import Table from '../../../components/Table';
import {Grid, IconButton} from '@mui/material';
import {Visibility} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';

type events = {
    isLoading: boolean;
    setPage: (value: number) => void;
    data: any;
};

export default function EventsList({isLoading, setPage, data}: events) {
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'eventName',
            headerName: 'event name',
            // headerAlign: 'center',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: '   text-gray-600',

            flex: 1,
        },
        {
            field: 'address',
            headerName: 'Address',
            // headerAlign: 'center',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: '   text-gray-600',

            flex: 1,
        },
        {
            field: 'ticketsInfo',
            headerName: 'tickets Info',
            // headerAlign: 'center',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: '   text-gray-600',
            flex: 1,
            renderCell: (params) => (
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <span className='text-gray-600 text-sm font-semibold pr-2 capitalize '>all :</span>
                        {params.value.all_tickets_qty || '.....'}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <span className='text-gray-600 text-sm font-semibold pr-2 capitalize'>sold :</span>
                        {params.value.all_tickets_qty
                            ? params.value.all_tickets_qty - params.value.remaining_tickets_qty
                            : '.....'}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <span className='text-gray-600 text-sm font-semibold pr-2 capitalize'>remaining :</span>
                        {params.value.remaining_tickets_qty || '.....'}
                    </Grid>
                </Grid>
            ),
        },

        {
            field: 'startDate',
            headerName: 'Event Starting Date',
            // headerAlign: 'center',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: '  text-gray-600',
            flex: 1,
        },
        {
            field: 'endDate',
            headerName: 'event end date',
            // headerAlign: 'center',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: '  text-gray-600',
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'view',
            // headerAlign: 'center',
            headerClassName: ' uppercase bg-green-700 text-white font-serif',
            cellClassName: '  text-gray-600',
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <IconButton
                    color='success'
                    size='medium'
                    // style={{ marginLeft: 16 }}
                    onClick={() => navigate(`/events/${params.value}`)}>
                    <Visibility fontSize='medium'/>
                </IconButton>
            ),
        },
    ];
    const rows = data.map((data: any) => {
        return {
            id: data.event_id,
            eventName: data.event_name_en,
            address: data.event_address,
            ticketsInfo: data,
            startDate: moment(data.event_start_date).format('DD/MM/YYYY, h:mm:ss a'),
            endDate: moment(data.event_end_date).format('DD/MM/YYYY, h:mm:ss a'),
            action: data.event_id,
        };
    });

    return (
        <Box sx={{height: '100%', width: '100%'}}>
            <Table
                cols={columns}
                rowsData={rows}
                isLoading={isLoading}
                enablePagination
                pagination={setPage}
            />
        </Box>
    );
}
