import moment from 'moment';
import { validateMobileForQueryParams } from '../../scripts/events-helpers';
import http from './http';

type payload = {
  from: string | null;
  to: string | null;
  mobile: string;
  ticket_number:string
  status_id: number | undefined | string;
  type_id: number | undefined | string;
};

const getEventTickets = async (
  eventId: string,
  providerId: string,
  page: number,
  payload: payload
) => {
  try {
    const res = await http.get(
      `/provider_dashboard/tickets?provider_id=${providerId}&event_id=${eventId}&page_size=25&page_number=${page}${
        payload.mobile && payload.mobile !== ''
          ? `&mobile=${validateMobileForQueryParams(payload.mobile)}`
          :''
      }${
          payload.ticket_number && payload.ticket_number !== ''
              ? `&ticket_number=${payload.ticket_number}`
              :''
      }${payload.type_id && payload.type_id!=="null"? `&ticket_type_id=${payload.type_id}` : ''}${
        payload.status_id && payload.status_id!=="null" ? `&status_id=${payload.status_id}` : ''
      }${payload.from ? `&from_date=${moment(payload.from).utc()}` : ''}${
        payload.to ?`&to_date=${moment(payload.to).utc()}` : ''
      }
      `
    );
    return res.data;
  } catch (err: any) {
    throw err.response;
  }
};

export default getEventTickets;
